<template>
  <div>
    <b-card>
      <b-row>
        <h6 class="section-label mx-1">
          Coupons
        </h6>
      </b-row>

    </b-card>
    <div>
      <b-form ref="form" :style="{ height: trHeight }" class="repeater-form" @submit.prevent="repeateAgain">
        <div v-for="(item, index) in items" :id="item.id" :key="item.id" ref="row" class="pb-2">
          <b-card>
            <b-row>
              <!-- Coupon Name -->
              <b-col md="4">
                <b-form-group label="Coupon Name" label-for="coupon-name">
                  <b-form-input id="coupon-name" type="text" v-model="item.title" placeholder="Coupon Name" />
                </b-form-group>
              </b-col>
              <!-- Coupon Code -->
              <b-col md="4">
                <b-form-group label="Coupon Code" label-for="coupon-code">
                  <b-form-input id="coupon-code" type="text" v-model="item.code" placeholder="Coupon Code" />
                </b-form-group>
              </b-col>

              <!-- Maxiumum Usage -->
              <b-col md="2">
                <b-form-group :label="'Max Usage'" label-for="maxUsage">
                  <b-form-input id="maxUsage" type="number" v-model="item.max_usage" />
                </b-form-group>
              </b-col>

              <!-- Usage -->
              <b-col md="2">
                <b-form-group :label="'used'" label-for="usage">
                  <b-form-input id="usage" type="number" v-model="item.usage" disabled />
                </b-form-group>
              </b-col>

              <!-- Type -->
              <b-col md="4">
                <b-form-group label="Type" label-for="type">
                  <b-form-select v-model="item.discount_type" :options="optionsType" />
                </b-form-group>
              </b-col>

              <!-- Amount -->
              <b-col md="4">
                <b-form-group label="Discount Amount" label-for="amount">
                  <b-form-input id="amount" type="text" v-model="item.discount" placeholder="Discount Amount" />
                </b-form-group>
              </b-col>
              <b-col md="2" lg="2">
                <b-form-group label="Valid From" label-for="valid_from">
                  <flat-pickr v-model="item.valid_from" class="form-control" id="valid_from" :config="{ dateFormat: 'd/m/Y' }" />
                </b-form-group>
              </b-col>
              <b-col md="2" lg="2">
                <b-form-group label="Valid Until" label-for="valid_to">

                  <flat-pickr v-model="item.valid_to" class="form-control" :config="{ dateFormat: 'd/m/Y' }" id="valid_to" />
                </b-form-group>
              </b-col>
              <!-- save Button -->
              <b-col lg="2" md="2" class="mb-50">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="success" class="mt-0 mt-md-2" block @click="saveItem(index, item.created_at)">
                  <feather-icon icon="CheckIcon" class="mr-25" />
                  <span>Save</span>
                </b-button>
              </b-col>
              <!-- Remove Button -->
              <b-col lg="2" md="2" class="mb-50">
                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" block @click="removeItem(index, item.created_at)">
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Delete</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-form>
    </div>
    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeateAgain">
      <feather-icon icon="PlusIcon" class="mr-25" />
      <span>Add New</span>
    </b-button>

  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BFormSelect, BCard
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

import useJwt from "@/auth/jwt/useJwt";
import _ from "lodash";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment"
export default {
  components: {
    BForm,
    BRow,
    BFormSelect,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    flatPickr,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [{}],
      nextTodoId: 2,
      optionsType: [{
        value: 'percent', text: 'Percantage Discount'
      }, {
        value: 'flat', text: 'Flat Amount Discount'
      }]
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    useJwt
      .get_coupons()
      .then((response) => {
        let data = response.data.data;
        for (let x = 0; x < data.length; x++) {
          data[x].status = false;
        }

        localStorage.setItem("userCoupons", JSON.stringify(data));

        this.items = data;
        for (let x = 0; x < this.items.length; x++) {
          this.items[x].valid_from = moment(this.items[x].valid_from, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY');
          this.items[x].valid_to = moment(this.items[x].valid_to, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY');
        }

        this.trSetHeight(null)
        this.$nextTick(() => {
          this.trSetHeight(this.$refs.form.scrollHeight)
        })
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your order data from the server.`,
          },
        });
      });

  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index, date) {
      let coupon = this.items[index];
      if (date) {
        useJwt
          .update_coupon(coupon.id, {
            is_deleted: 1
          })
          .then((response) => {
            this.items.splice(index, 1)
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "CropIcon",
                variant: "danger",
                text: `We couldn't update coupon, please try again later.`,
              },
            });
          });
      } else {
        this.items.splice(index, 1)
        this.trTrimHeight(this.$refs.row[0].offsetHeight)
      }
    },
    saveItem(index, date) {
      let coupon = this.items[index];
      if (date) {
        let val_valid_from, val_valid_to
        if (moment(coupon.valid_from, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss') != "Invalid date") {
          val_valid_from = moment(coupon.valid_from, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss')
        } else {
          val_valid_from = moment(coupon.valid_from, 'YYYY-mm-dd HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
        }

        if (moment(coupon.valid_to, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss') != "Invalid date") {
          val_valid_to = moment(coupon.valid_to, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss')
        } else {
          val_valid_to = moment(coupon.valid_to, 'YYYY-mm-dd HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
        }
        if (moment(val_valid_to).isBefore(val_valid_from)) {
          return this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't update coupon, coupon expiry date is before it is started.`,
            },
          });
        }
        useJwt.update_coupon(coupon.id, {
          title: coupon.title,
          code: coupon.code,
          usage: coupon.usage,
          max_usage: coupon.max_usage,
          discount: coupon.discount,
          discount_type: coupon.discount_type,
          valid_from: val_valid_from,
          valid_to: val_valid_to,
        })
          .then(() => {

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Coupon Saved`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `You have successfully updated coupon ${coupon.title}!`,
              },
            });

          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "CropIcon",
                variant: "danger",
                text: `We couldn't update coupon, please try again later.`,
              },
            });
          });
      } else {
        console.log(coupon)
        useJwt
          .new_coupon({
            title: coupon.title,
            code: coupon.code,
            max_usage: coupon.max_usage,
            discount: coupon.discount,
            discount_type: coupon.discount_type,
            valid_from: moment(coupon.valid_from, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss'),
            valid_to: moment(coupon.valid_to, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss'),
          })
          .then((response) => {

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Coupon Saved`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `You have successfully created new coupon ${coupon.title}!`,
              },
            });
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "CropIcon",
                variant: "danger",
                text: `We couldn't update coupon, please try again later.`,
              },
            });
          });
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
